import React from "react";
import { SocialIcon } from 'react-social-icons';
import "./home.css";
import EmailIcon from '@mui/icons-material/Email';
import { Button } from "@mui/material";
import CvPdf from '../../CV.pdf';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import About from "../About/About";
import Projects from "../Projects/Projects";
import Contact from "../Contact/Contact";
import GitHubCalendarComponent from "../Github/githubCalendar";

const Home = () => {
    return (
        <div className="home-container">
            <section id="section-1" className="section">
                <div className="hello">
                    <div className="text">
                        <h1 className="name">Janberk ALTAY</h1>
                        <h2 className="title">Junior Software Developer</h2>
                        <div className="mail"><EmailIcon style={{ fontSize: 34, marginRight: 12 }} />janberk.altayy@gmail.com</div>
                        <GitHubCalendarComponent username={"janberkaltay"} />
                        <div className="social">
                            <SocialIcon target="_blank" rel="noopener noreferrer" url="https://github.com/janberkaltay" />
                            <SocialIcon target="_blank" rel="noopener noreferrer" url="https://twitter.com/janberkaltay" />
                            <SocialIcon target="_blank" rel="noopener noreferrer" url="https://www.instagram.com/janberkaltay/" />
                            <SocialIcon target="_blank" rel="noopener noreferrer" url="https://www.linkedin.com/in/janberkaltay/" />
                            <SocialIcon target="_blank" rel="noopener noreferrer" url="https://www.youtube.com/channel/UCgkNm9R0i3NftVxcBv_Jqmg" />
                        </div>
                        <div className="cv-button">
                            <a
                                href={CvPdf}
                                download="Janberk Altay CV"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Button style={{ backgroundColor: '#9370db' }} variant="contained" endIcon={<FileDownloadIcon />}>CV</Button>
                            </a>
                        </div>
                    </div>
                    <img alt="" className="photo" src='img/janberkaltay.jpg' />
                </div>
            </section>
            <About />
            <Projects />
            <Contact />

        </div>
    )
}
export default Home;
